<template>
  <dialogForm
    :option="eidtOption"
    width="400"
    title="编辑"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="form"
    @opened="onOpen"
    @submit="onSubmit"
  >
    <template #reference>
      <color-text-btn style="font-size: 12px">编辑</color-text-btn>
    </template>
  </dialogForm>
</template>

<script>
import attributeApi from '@/api/productTemplate/attributeManage'
import { eidtOption } from './option'

export default {
  props: {
    data: Object,
    sup_this: Object
  },

  data() {
    return {
      eidtOption,
      form: {}
    }
  },

  methods: {
    onOpen() {
      this.$set(this.form, 'fieldName', this.data.fieldName)
    },

    async onSubmit(data, done) {
      const { id } = this.data
      const res = await awaitResolve(
        attributeApi.update({
          id,
          fieldName: this.form.fieldName,
          templateUsageType:2,
        })
      )
      if (!res) return done(false)
      done(true)
      this.sup_this.init()
    }
  }
}
</script>
